import { Component, HostBinding, Input } from '@angular/core';
import { TranslateDirective, TranslatePipe } from '@ngx-translate/core';
import { DialogComponent } from '@webclient/modal/dialog';
import { ModalDialogComponent } from '@webclient/standalones/modal-dialog';
import { ModalButtons } from '@webclient/modal/message-box';

@Component({
    template: `
        <app-modal-dialog [buttons]="buttons" #dialog (submitted)="onSubmit()">
            <h4 dialog-header class="mb-0">{{ dialogTitle| translate }}</h4>
            @if (dialogMainInfo; as text) {
                <p>{{ text | translate }}</p>
            }
                            <ul>
                                <li [translate]="'_i18n.Install3cxPwaAppInfo1'">Eliminates login and tabs.</li>
                                <li [translate]="'_i18n.Install3cxPwaAppInfo2'">Get notified for incoming calls or chats.</li>
                                <li [translate]="'_i18n.Install3cxPwaAppInfo3'">Automatic updates.</li>
                                <li [translate]="'_i18n.Install3cxPwaAppInfo4'">Runs securely within your browser.</li>
                                <li [translate]="'_i18n.Install3cxPwaAppInfo5'">Use in tandem with iOS, Android & deskphone.</li>
                                <li><span [innerHTML]="'_i18n.Install3cxPwaAppInfo6' | translate"></span></li>
                            </ul>
                            <div class="text-center py-4">
                                            <button class="btn btn-primary actionButton" type="button"
                                                    [disabled]="!isPwaInstallAvailable"
                                                    (click)="dialog.submit()">
                                                <span class='text-truncate' [title]="'_i18n.InstallNow' | translate">{{'_i18n.InstallNow' | translate}}</span>
                                            </button>
                            </div>
        </app-modal-dialog>
    `,
    styleUrls: ['show-apps-dialog.component.scss'],
    standalone: true,
    imports: [TranslatePipe, ModalDialogComponent, TranslateDirective]
})
export class ShowAppsDialogComponent extends DialogComponent<{ installPwaApp?: boolean }> {
    override readonly buttons = ModalButtons.None;

    @Input() showCeDeprecation = false;
    @Input() isPwaInstallAvailable: boolean;

    @HostBinding('class.modal-pwa') readonly modalPwa = true;

    protected override getPayload(): { installPwaApp?: boolean } | undefined {
        return { installPwaApp: true };
    }

    get dialogTitle(): string {
        return this.showCeDeprecation ? '_i18n.DeprecateExtensionTitlePwa' : '_i18n.Install3cxPwa';
    }

    get dialogMainInfo(): string {
        return this.showCeDeprecation ? '_i18n.DeprecateExtensionMainInfoPwa' : '';
    }
}
