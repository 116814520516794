import { Injectable, inject } from '@angular/core';
import { isChrome, isEdge, isMacOS, isMobileDevice, isWindows } from '@webclient/browser-type';
import { ShowAppsDialogComponent } from '@webclient/standalones/show-apps-dialog/show-apps-dialog.component';
import { ModalService } from '@webclient/modal/app-modal.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { BehaviorSubject, fromEvent, merge, of, Subscription, switchMap } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { switchToDialogPayload } from '@webclient/rx-utils';

@Injectable({
    providedIn: 'root'
})
export class AppsUtilityService {
    private modalService = inject(ModalService);
    private mdr = inject(MediaMatcher);

    private isPwaStandAloneQuery: MediaQueryList;
    private pwaInstallPrompt: BehaviorSubject<any|null> = new BehaviorSubject<any|null>(null);
    private pwaInstallationEventsSubscription: Subscription;

    readonly isWindows = isWindows;

    constructor() {
        this.isPwaStandAloneQuery = this.mdr.matchMedia('(display-mode: standalone)');
    }

    listenPwaInstallationEvents() {
        if (this.pwaInstallationEventsSubscription) {
            return;
        }
        this.pwaInstallationEventsSubscription = merge(
            fromEvent<any>(window, 'beforeinstallprompt').pipe(
                map((installPrompt) => installPrompt)
            ),
            fromEvent<any>(window, 'appinstalled').pipe(
                map(() => null)
            ),
        ).subscribe((installPrompt) => {
            this.pwaInstallPrompt.next(installPrompt ?? null);
        });
    }

    showPWAModal(showCeDeprecation = false) {
        if (this.isProgressiveWebAppVisible()) {
            this.modalService.showDialog(ShowAppsDialogComponent, { showCeDeprecation, isPwaInstallAvailable: this.isPwaInstallAvailable() })
                .pipe(switchToDialogPayload())
                .subscribe(({ installPwaApp }) => {
                    if (installPwaApp) {
                        this.firePwaInstallation();
                    }
                });
        }
    }

    firePwaInstallation() {
        // https://www.amitmerchant.com/adding-custom-install-button-in-progressive-web-apps/
        // https://developer.mozilla.org/en-US/docs/Web/API/BeforeInstallPromptEvent
        // https://web.dev/customize-install/
        this.pwaInstallPrompt.pipe(
            take(1),
            switchMap((prompt :any) => {
                if (prompt) {
                    prompt.prompt();
                    return prompt.userChoice;
                }
                else {
                    return Promise.resolve(null);
                }
            }),
            catchError((err: unknown) => {
                console.log(err);
                return of(null);
            })
        ).subscribe((result :any) => {
            if (result) {
                const { outcome } = result;
                if (outcome && outcome === 'accepted') {
                    this.pwaInstallPrompt.next(null);
                }
            }
        });
    }

    isPwaInstallAvailable() : boolean {
        return !!this.pwaInstallPrompt.getValue();
    }

    isDesktopAppSupportedInOs() {
        return (isWindows || isMacOS);
    }

    isProgressiveWebAppVisible() {
        return !isMobileDevice() && (isChrome || isEdge) && !this.isPwaStandAlone() && this.isPwaInstallAvailable();
    }

    isChromeExtAppVisible() {
        return isChrome && !isMobileDevice();
    }

    isEdgeExtAppVisible() {
        return isEdge && !isMobileDevice();
    }

    isWindowsPhoneAppVisible() {
        return isWindows;
    }

    // for more look at https://web.dev/customize-install/
    isPwaStandAlone(): boolean {
        const isAndroid = document.referrer.startsWith('android-app://');
        const isStandalone = (navigator as any).standalone || this.isPwaStandAloneQuery.matches;
        return isAndroid || isStandalone;
    }
}
